interface IRentalsCatalog {
  imgUrl: string,
  heading: string,
  content: string,
  pageUrl: string
}


const RENTALS_CATALOG: IRentalsCatalog[] = [
  {
    imgUrl: "/images/rentals/dslr-lenses.icon.svg",
    heading: "Lenses",
    content: "Choose from our wide varieties of camera lenses to capture the perfect photo.",
    pageUrl: "/rentals/lenses"
  },
  {
    imgUrl: "/images/rentals/lights.icon.svg",
    heading: "Lighting",
    content: "Look your best in photos and videos with our studio lights.",
    pageUrl: "/rentals/lights"
  },
  {
    imgUrl: "/images/rentals/dslr-cameras.icon.svg",
    heading: "Cameras",
    content: "Capture anything in High Definition with our Cameras.",
    pageUrl: "/rentals/cameras"
  },
]

export {
  RENTALS_CATALOG
}
import { RENTALS_CATALOG } from "./rentals-catalog.list";
import styles from "./rentals-catalog.module.css"
import { Link } from "react-router-dom";
const RentalsCatalogList = () => {
  return (
    <ol className={styles.List}>
      {
        RENTALS_CATALOG.map((catalog, index) => {
          return (
            <li className={styles.Item} key={index}>
              <div className={styles.Content}>
                <img className={styles.Icon} src={catalog.imgUrl} alt={`${catalog.heading} Icon`} />
                <h1>{catalog.heading}</h1>
                <p className={styles.Description}>{catalog.content}</p>
                <Link className={styles.CatalogURL} to={catalog.pageUrl}>Explore {catalog.heading}</Link>
              </div>
            </li>
          );
        })
      }
    </ol>
  );
}

export { RentalsCatalogList }
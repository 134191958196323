import { useRouteError } from "react-router-dom";

type RouteErrorResponse = {
  "status": number,
  "statusText": string,
  "internal": boolean,
  "data": string
  "error": {
    "message": string,
    "statck": string
  }
}


export default function ErrorPage() {
  const error: RouteErrorResponse = useRouteError() as RouteErrorResponse;
  console.log(error);
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.data}</i>
      </p>
    </div>
  );
}

import { Link } from 'react-router-dom';
import styles from './NavItem.module.css'
import DropDown from './dropdown/DropDown';
import { useState } from 'react';
import { useDeviceType } from '../../../../../hooks/useDeviceType';
import { IRentalsNavigationCategory } from '../../../../../services/application/rentals/models/i-rentals-navigation-catagory';


const NavItem = (props: IRentalsNavigationCategory) => {
  const deviceType = useDeviceType();
  const [showDropDown, setShowDropDown] = useState(false);

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    (e.target as HTMLElement).scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    if (showDropDown) {
      setShowDropDown(false);
    }
  }

  const eventDropDownHandlers = {
    onMouseEnter: () => setShowDropDown(true),
    onMouseLeave: () => setShowDropDown(false),
    onClick: (e: React.MouseEvent<any>) => { onClickHandler(e) }
  }

  return (
    <div {...(deviceType === 'desktop' && { ...eventDropDownHandlers })}>
      {!props.navigations &&
        <Link
          className={styles.NavHeading} to={props.href}
        >
          {props.name}
        </Link>
      }

      {props.navigations &&
        <>
          <button
            {...(deviceType === 'mobile' && { ...eventDropDownHandlers })}
            className={styles.NavHeading}
          >
            {props.name}
          </button>
          <DropDown
            links={props.navigations}
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
          ></DropDown>
        </>
      }
    </div>
  );
}


export default NavItem;
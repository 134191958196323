import ServicesList from './servicesList/servicesList';
import styles from './services.module.css'


const Services = () => {
  return (
    <section className={styles.offeringsSection}>
      <h1 className='headerText'>Services</h1>
      <ServicesList />
    </section>
  );
}

export default Services;
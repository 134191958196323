import styles from './page.module.css'
import Services from './services/services'
import About from './about/about'
import Clients from './clients/clients'


export default function Home() {
  return (
    <main className={styles.main}>
      <Services ></Services>
      <Clients></Clients>
      <About></About>
    </main>
  )
}
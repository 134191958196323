import styles from './about.module.css'

const About = () => {
  return (
    <section className={styles.aboutSection}>
      <h1 className='headerText'>About Us</h1>
      <div className={styles.content}>
        <p>WhiteBalance is an production house founded in 2017 and head quartered in bangalore city.</p>
        <p>We help our clients with hosting and distribution of live and on demand video content in the resolutions upto 4K.</p>
        <p>We have over 50+ happy clients and also provide services to organizations in the media industry.</p>
      </div>
    </section >
  );
}

export default About;
import RootLayout from "./root-layout/root-layout";

import Home from "./pages/home/page";
import Testimonials from "./pages/testimonials/page";
import Contact from "./pages/contact/page";
import LiveStreaming from "./pages/live-streaming/page";


import Rentals from "./pages/rentals/categories-page/page";
import RentalsLayout from "./pages/rentals/layout/Layout";
import { RentalsHome } from "./pages/rentals/home-page/rentals-home";


import ErrorPage from "./pages/error/error";
// import { CartPage } from "./pages/rentals/cart-page/cart-page";

import { RentalsNavigationService } from './services/application/rentals/rentals-navigation.service';

const ROUTES = [{
  element: <RootLayout></RootLayout>,
  errorElement: < ErrorPage />,
  children: [
    {
      path: "/",
      element: <Home></Home>,

    },
    {
      path: "/contact",
      element: <Contact></Contact>
    },
    {
      path: "/testimonials",
      element: <Testimonials></Testimonials>
    },
    {
      path: "/live-streaming",
      element: <LiveStreaming></LiveStreaming>
    }

  ]
},
];

// if (RentalsNavigationService.canShowRentals()) {
ROUTES.push({
  element: <RentalsLayout></RentalsLayout>,
  errorElement: < ErrorPage />,
  children: [
    {
      path: "/rentals/:category",
      element: <Rentals></Rentals>
    }, {
      path: "/rentals",
      element: <RentalsHome></RentalsHome>
    },

    // {
    //   path: "/rentals/cart",
    //   element: <CartPage />
    // }
  ]
});
// }


export { ROUTES };
import styles from './contact.module.css'

const Contact = () => {
  // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   console.log(e);
  // }

  return (
    // <main className={styles.main}>
    //   <div className={styles.contactCard}>
    //     <h1>GET IN TOUCH</h1>
    //     <p>Leave a message and we will get back to you.</p>
    //     <form className={styles.contactForm} onSubmit={handleSubmit}>
    //       <div className="field">
    //         <input id='contact-name' type="text" placeholder='Name' />
    //       </div>
    //       <div className="field">
    //         <input id='contact-email' type="email" placeholder='Email' />
    //       </div>
    //       <div className="field">
    //         <input id='contact-phone-number' type="tel" placeholder='Phone Number' />
    //       </div>
    //       <div className="field">
    //         <textarea id='contact-message' placeholder='Message' />
    //       </div>

    //       <div>
    //         <button type='submit'>Send Message</button>
    //       </div>
    //     </form>
    //   </div>
    // </main>
    <main className={styles.main}>
      <div className={styles.contactCard}>
        <h1>GET IN TOUCH</h1>
        <p>Leave a message and we will get back to you.</p>
        <p><a href="tel:+919901302464">+91 9901302464</a></p>
        <p><a href="mailto:whitebalanceindia@outlook.com">whitebalanceindia@outlook.com</a></p>
      </div>
    </main >
  );
}


export default Contact
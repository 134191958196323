import styles from './clients.module.css'
import clientsList from './clientsList'
import { Client } from './clientsList';
export default function Clients() {
  return (
    <section className={styles.clients}>
      <h1 className='headerText'>Clients</h1>
      <h3>Checkout a few of our happy customers.</h3>
      <ul className={styles.clientGallery}>
        {
          clientsList.map((clientItem: Client, index: number) => {
            return (
              <li key={index + 1}>
                <figure className={styles.item} style={{ background: clientItem.themeColor }}>
                  <img src={clientItem.logoUrl} alt="" />
                  {/* <figcaption>{clientItem.companyName}</figcaption> */}
                </figure>
              </li>
            );
          })
        }
      </ul>
    </section>
  );
}
import styles from './footer.module.css'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <section className={styles.section}>
        <h1 className={styles.h1}>WhiteBalance</h1>
        <h1 className={styles.Heading}>Your one stop production agency</h1>
      </section>
      <section className={styles.section}>
        <h1 className={styles.h1}>Address</h1>
        <address>
          <p >50, Karumariamman Temple Rd</p>
          <p >AravindNagar, KSFC Layout, Lingarajapuram</p>
          <p >Bengaluru, Karnataka 560084</p>
        </address>
        <a
          href="https://www.google.com/maps/dir//white+balance+studios/@13.0059993,77.6190053,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae17c58fce1c75:0x7c7853f952f14391!2m2!1d77.630784!2d13.011267"
        >
          <img src="/images/footer/map.svg" alt="Map icon" className={styles.Icons} />
          See on Map &#8599;
        </a>
      </section>
      <section className={styles.section}>
        <h1 className={styles.h1}>Contact</h1>
        <a href="mailto:whitebalanceindia@outlook.com">
          <img src="/images/footer/email.svg" alt="Email icon" className={styles.Icons} />
          whitebalanceindia@outlook.com
        </a>
        <a href="tel:+919901302464">
          <img src="/images/footer/phone.svg" alt="Phone icon" className={styles.Icons} />
          +91 9901302464
        </a>
      </section>
      <section className={styles.section}>
        <h1 className={styles.h1}>Social</h1>
        <a
          href="https://www.facebook.com/profile.php?id=100091088146894&mibextid=ZbWKwL"
          title='White Balance Facebook link'
        >
          <img src="/images/footer/facebook.svg" alt="Facebook logo" className={styles.Icons} />
          Facebook
        </a>
        <a
          href="https://www.instagram.com/whitebalance.co.in/?igshid=YmMyMTA2M2Y%3D"
          title='White Balance Instagram link'
        >
          <img src="/images/footer/instagram.svg" alt="Instagram logo" className={styles.Icons} />
          Instagram
        </a>
      </section>
    </footer>
  );
}

export default Footer;
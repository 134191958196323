
import { INRFormatter } from '../../../../../services/application/formatters/inr-formatter';
import styles from './item-card.module.css';
// import { ItemCardActions } from './item-card-actions/item-card-actions';
import { IRental } from '../../../../../services/application/rentals/models/i-rental';



const Items = (props: IRental) => {
  return (
    <div className={styles.ItemCard} data-product-id={props.id}>
      <input type="hidden" value={props.id} disabled={true} />
      <img className={styles.Img} src={props.imgURL} alt={props.name} />
      <h3 className={styles.Name}>{props.name}</h3>
      <div className={styles.Row}>
        <p className={styles.Price}>At <b className={'amount'}>{INRFormatter.format(parseFloat(props.price))}</b></p>
        {/* <ItemCardActions {...props} /> */}
      </div>
      <div className={styles.Tags}>
        {
          Object.keys(props.tags).map((tag, index) => <span key={index} className={styles.Tag}>{tag}</span>)
        }
      </div>
    </div>
  );
}


export default Items;


import { useEffect, useState } from "react";
import styles from './nav.module.css'
import NavItem from "./nav-item/NavItem";
import { RentalsNavigationService } from "../../../../services/application/rentals/rentals-navigation.service";
import { IRentalsNavigationCategory } from "../../../../services/application/rentals/models/i-rentals-navigation-catagory";


const Header = () => {
  const [isLoading, setIsLoading] = useState(true);
  let [navLinks, setNavLinks] = useState<IRentalsNavigationCategory[]>([]);;

  useEffect(() => {
    RentalsNavigationService.getNavigations()
      .then((data) => {
        setNavLinks(data);
        setIsLoading(false);
      });
  }, []);


  return (
    <header>
      {isLoading && 'Data is loading...'}
      {!isLoading &&
        <nav className={styles.rentalsNav}>
          {
            navLinks.map((category, index) => {
              return <NavItem key={index} {...category}></NavItem>;
            })
          }
        </nav>
      }

    </header>
  );
}

export default Header;
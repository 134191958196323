import styles from './servicesList.module.css';
import services from './data/services';
import ServiceType from './data/serviceType';
import OfferingItem from './servicesItem/servicesItem';

const ServicesList = () => {

  return (
    <div className={styles.offeringsList}>
      {
        services.map((offeringItem: ServiceType, index: number) => {
          return <OfferingItem key={index} {...offeringItem} />
        })
      }
    </div>
  );
}

export default ServicesList;
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import ItemsList from "./items-list/items-list";
import styles from './page.module.css'
import SubNav from "./subnav/SubNav";

import { RentalsQueryService } from "../../../services/application/rentals/rentals-query.service";
import { RentalsNavigationService } from "../../../services/application/rentals/rentals-navigation.service";
import { IRental } from "../../../services/application/rentals/models/i-rental";
import { IRentalsNavigation } from "../../../services/application/rentals/models/i-rentals-navigation";


const Rentals = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [rentals, setRentals] = useState<IRental[]>([]);
  const [navigations, setNavigations] = useState<IRentalsNavigation[] | undefined>([]);
  const urlParms = useParams();
  const location = useLocation();

  useEffect(() => {
    async function action() {
      const rentals = await RentalsQueryService.getByCategory(urlParms.category as string);
      const navigations = RentalsNavigationService.getNavigationsForCatagory(location.pathname);
      setRentals(rentals);
      setNavigations(navigations);
      setIsLoading(false);
    }
    action();
  }, [urlParms.category, location.pathname]);

  return (
    <>
      {isLoading && <main>Data is loading</main>}
      {!isLoading && <h1 className={styles.Heading}>{urlParms.category?.replaceAll("-", " ")}</h1>}
      {!isLoading && navigations && <SubNav items={navigations}></SubNav>}
      {!isLoading && rentals.length === 0 &&
        <main className={styles.Content}>
          <h2 className={styles.Heading}>Nothing Yet, but will be added soon.</h2>
        </main>
      }
      {!isLoading && rentals.length > 0 &&
        <main>
          {
            <div className={styles.Content}>
              <ItemsList className={styles.Content} items={rentals}></ItemsList>
            </div>
          }
        </main>
      }
    </>

  );
}


export default Rentals;

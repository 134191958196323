import styles from "./rentals-home.module.css"
import { RentalsCatalogList } from "./rentals-catalog/rentals-catalog";
const RentalsHome = () => {
  return (
    <main className={styles.Page}>
      <section>
        <h1 className={styles.Heading}>Rentals</h1>
        <h2 className={styles.Heading2}>Get more, Pay Less.</h2>
      </section>
      <section className={styles.CatalogContainer}>
        <RentalsCatalogList />
      </section>
    </main>
  );
}

export {
  RentalsHome
}
export type Client = { companyName: string, logoUrl: string, themeColor?: string }

const clientsList: Client[] = [
  {
    companyName: "Microsoft",
    logoUrl: "/images/clients/clients-microsoft-logo.svg",
    themeColor: 'white'
  },
  {
    companyName: "Swiggy",
    logoUrl: "/images/clients/clients-swiggy-logo.svg",
    themeColor: 'white'
  },
  {
    companyName: "cult.fit",
    logoUrl: "/images/clients/clients-cult-fit-logo.webp",
    themeColor: 'black'
  },
  {
    companyName: "Unilever",
    logoUrl: "/images/clients/clients-unilever-logo.svg",
    themeColor: 'white'
  },
  {
    companyName: "cricbuzz",
    logoUrl: "/images/clients/clients-cricbuzz-logo.png",
    themeColor: 'white'
  },
  {
    companyName: "Manipal",
    logoUrl: "/images/clients/clients-mahe-logo.svg",
    themeColor: 'white'
  },
];


export default clientsList;
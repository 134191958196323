import { IRentalsNavigationCategory } from "./models/i-rentals-navigation-catagory";

const rentalsNavigationList: IRentalsNavigationCategory[] = [
  {
    "name": "Home",
    "href": "/",
    "iconUrl": "/images/rentals/home.icon.svg",
  },
  {
    "navigations": [
      {
        "name": "All",
        "href": "/rentals/accessories",
        "iconUrl": "/images/rentals/accessories.icon.svg"
      },
      {
        "name": "Light Stands",
        "href": "/rentals/light-stands",
        "iconUrl": "/images/rentals/light-stands.icon.svg",
      }, {
        "name": "Monitors",
        "href": "/rentals/monitors",
        "iconUrl": "/images/rentals/monitors.icon.svg",
      }

    ],
    "name": "Accessories",
    "href": "/rentals/accessories",
    "iconUrl": "/images/rentals/accessories.icon.svg"
  },
  {
    "name": "Audio & Sounds",
    "href": "/rentals/audio-&-sounds",
    "iconUrl": "/images/rentals/audio-&-sound.icon.svg",
    "navigations": [
      {
        "name": "All",
        "href": "/rentals/audio-&-sounds",
        "iconUrl": "/images/rentals/audio-&-sound.icon.svg",
      },
      {
        "name": "Audio Recorders", "href": "/rentals/audio-recorders",
        "iconUrl": "/images/rentals/audio-recorders.icon.svg",
      },
      {
        "name": "Head Phones", "href": "/rentals/head-phones",
        "iconUrl": "/images/rentals/headphones.icon.svg",
      },
      {
        "name": "Microphones", "href": "/rentals/microphones",
        "iconUrl": "/images/rentals/microphones.icon.svg",
      },

    ]
  },
  {
    "navigations": [
      {
        "name": "All",
        "href": "/rentals/cameras",
        "iconUrl": "/images/rentals/camera.icon.svg",
      },
      {
        "name": "Cinema Cameras", "href": "/rentals/cinema-cameras",
        "iconUrl": "/images/rentals/cinema-cameras.icon.svg",
      },
      {
        "name": "DSLR Cameras", "href": "/rentals/dslr-cameras",
        "iconUrl": "/images/rentals/dslr-cameras.icon.svg",
      },
      {
        "name": "Video Cameras", "href": "/rentals/video-cameras",
        "iconUrl": "/images/rentals/video-cameras.icon.svg",
      },

    ],
    "name": "Cameras",
    "href": "/rentals/cameras",
    "iconUrl": "/images/rentals/camera.icon.svg",
  },
  {
    "name": "Lenses",
    "href": "/rentals/lenses",
    "iconUrl": "/images/rentals/lenses.icon.svg",
    "navigations": [
      {
        "name": "All",
        "href": "/rentals/lenses",
        "iconUrl": "/images/rentals/lenses.icon.svg",
      },
      {
        "name": "Cine Lenses", "href": "/rentals/cine-lenses",
        "iconUrl": "/images/rentals/cinema-lenses.icon.svg",
      },
      {
        "name": "DSLR Lenses", "href": "/rentals/dslr-lenses",
        "iconUrl": "/images/rentals/dslr-lenses.icon.svg",
      },
      {
        "name": "Filters", "href": "/rentals/filters",
        "iconUrl": "/images/rentals/filters.icon.svg",
      },
    ]
  },
  {
    "name": "Lights",
    "href": "/rentals/lights",
    "iconUrl": "/images/rentals/lights.icon.svg",
    "navigations": [
      {
        "name": "All",
        "href": "/rentals/lights",
        "iconUrl": "/images/rentals/lights.icon.svg",
      },
      {
        "name": "Flash Lights", "href": "/rentals/flash-lights",
        "iconUrl": "/images/rentals/flash-lights.icon.svg",
      },
      {
        "name": "Cinema Lights", "href": "/rentals/cinema-lights",
        "iconUrl": "/images/rentals/cinema-lights.icon.svg",
      },
      {
        "name": "Reflectors", "href": "/rentals/reflectors",
        "iconUrl": "/images/rentals/reflectors.icon.svg",
      },
      {
        "name": "Strobe Lights", "href": "/rentals/strobe-lights",
        "iconUrl": "/images/rentals/strobe-lights.icon.svg",
      },
      {
        "name": "Video Lights", "href": "/rentals/video-lights",
        "iconUrl": "/images/rentals/video-lights.icon.svg",
      },
    ]
  },
  // {
  //   "name": "Cart",
  //   "href": "/rentals/cart",
  //   "iconUrl": "/images/rentals/bag.icon.svg"
  // }
  // {
  //   "name": "Popular",
  //   "href": "/rentals/popular",
  //   "iconUrl": "/images/rentals/popular.icon.svg",
  // },
  // {
  //   "name": "New Arrivals",
  //   "href": "/rentals/new",
  //   "iconUrl": "/images/rentals/new.icon.svg",
  // },
];


export {
  rentalsNavigationList
}
import { Link } from "react-router-dom";
import styles from './DropDown.module.css'
import { Category } from "../../../../models/catagory.type";

type DropDownProps = {
  links: Category[],
  showDropDown: boolean,
  setShowDropDown: (value: boolean) => void
}

export default function DropDown(props: DropDownProps) {
  return (
    <ol className={`${styles.DropDown} ${props.showDropDown ? styles.show : ''}`}>
      {
        props.links.map((navLink, index) =>
          <li key={index} className={styles.Item}>
            <Link
              onClick={() => {
                console.log("Toggle");
                props.setShowDropDown(false);
              }}
              to={navLink.href}>{navLink.name}</Link>
          </li>
        )
      }
    </ol>
  );
}
import OfferingType from "../data/serviceType";
import styles from './servicesItem.module.css';

const OfferingItem = ({ iconUrl, heading, description }: OfferingType) => {
  return (
    <div className={styles.offering}>
      <img className={styles.icon} src={iconUrl} alt="" />
      <h2>{heading}</h2>
      <p>{description}</p>
    </div>
  );
}


export default OfferingItem;
import { FirestoreDB } from "../../firebase/FirestoreDB";
import { IRental } from "./models/i-rental";

export class RentalsQueryService {
  private static DAO = FirestoreDB.Instance;

  static categoryToSearchURI(category: string) {
    return decodeURIComponent(category).replaceAll(/\s|-/ig, '_').toUpperCase();
  }

  // static getByCategory(category: string) {
  //   const toSearchQuery = this.categoryToSearchURI(category);
  //   return (this.DAO.getCollectionData(toSearchQuery, true) as Promise<IRental[]>);
  // }


  static getByCategory(category: string) {
    const toSearchQuery = this.categoryToSearchURI(category);
    const fieldPath = `categories.${toSearchQuery}`;
    return (this.DAO.runQueryOnCollection('Rentals', fieldPath, '==', 1) as Promise<IRental[]>);
  }

  static getById(id: string) {
    return this.DAO.getDocumentFromCollection('Rentals', id) as Promise<IRental>;
  }

  static getByIds(idsList: string[]) {
    return this.DAO.queryDocumentIds('Rentals', idsList) as Promise<IRental[]>;
  }
}









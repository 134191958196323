export const MERIT_LIST = [
  {
    label: "SECURE",
    content: "Private link sharing with Password Protection"
  },

  {
    label: "ADS FREE",
    content: "Dedicated HD Live Broadcasting"
  },
  {
    label: "UNLIMITED",
    content: "Concurrent viewers"
  },
  {
    label: "ADAPTIVE",
    content: "Based on audience bandwidth"
  },
  {
    label: "QUALITY",
    content: "Multi - bitrate & 4k streaming"
  },

  {
    label: "TECHNOLOGY",
    content: "RTMP and cloud transcoding"
  },

  {
    label: "SERVICE",
    content: "Video Hosting and management"
  },

  {
    label: "SOCIAL MEDIA",
    content: "Stream into any app"
  },

  {
    label: "ANALYTICS",
    content: "Pre and post event statistics"
  },

  {
    label: "SOFTWARE",
    content: "Studio software for professional encoding"
  },
]


import ServiceType from './serviceType';

const offeringsList: ServiceType[] = [
  {
    iconUrl: '/images/offerings/offerings-video-editing-icon.svg',
    heading: 'Production Agency',
    description: 'We are a production agency providing services such as ad shoots, content videos along with editing.'
  },
  {
    iconUrl: '/images/offerings/offerings-live-streaming-icon.svg',
    heading: 'Live Streaming',
    description: 'We use live video platform (Vimeo, Livestream, & Wowza) for live streaming.'
  },
  {
    iconUrl: '/images/offerings/offerings-cameras-icon.svg',
    heading: 'Rentals',
    description: 'Select your choice of camera, lenses and lights from our listings available online.'
  },
];

export default offeringsList;

'use client'
import { EndorsementType } from "../endorsements.list";
import styles from './endorsement.module.css'
const Endorsement = (props: any) => {
  const endorsement: EndorsementType = props.endorsement;
  return (
    <div className={styles.endorsementCard}>
      <img src={endorsement.company.logoUrl} alt={`${endorsement.company.name}'s logo`} width='300' />
      <blockquote className={styles.quoted}>
        <p className={`${styles.description}`}>{endorsement.description}</p>
      </blockquote>
      <br />
      <span className={`${styles.name} ${styles.endorser}`} style={{ color: endorsement.themeColor }}>{endorsement.endorser?.name}</span>
      {endorsement.endorser?.role ?
        <span className={`${styles.role} ${styles.endorser}`}>{endorsement.endorser?.role}</span> :
        <></>
      }
    </div >
  );
}


export default Endorsement;
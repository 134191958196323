export type EndorsementType = {
  company: {
    name: string,
    logoUrl: string,
  },
  endorser?: {
    name: string,
    role?: string
  },
  themeColor?: string,
  description: string,
}

const ENDORSEMENT_LIST: EndorsementType[] = [{
  company: {
    name: 'Swiggy',
    logoUrl: '/images/clients/clients-swiggy-logo.svg'
  },
  endorser: {
    name: 'Harshith Gowda',
    role: 'Manager PMO IT'
  },
  themeColor: '#ff5d0d',
  description: 'As always support was very good. We have received postive feedback from shareholders at production. Overall, the service exceeded our expectations.'
},
{
  company: {
    name: 'AGM IT',
    logoUrl: '/images/clients/clients-agm-it-logo.webp'
  },
  endorser: {
    name: 'Anil'
  },
  themeColor: '#2eadf0',
  description: 'Thank you for arranging a resource like Kiran, he was thoughful and well planned. He carried the neccesary hardware in advance and saved the day for us and the AV guys. Convey our thanks to him.'
},
];


export {
  ENDORSEMENT_LIST
}
import styles from './page.module.css';
import { MERIT_LIST } from './merits.list';
export default function LiveStreaming() {
  return (<main className={styles.Main}>
    <section className={styles.Section}>
      <h1 className={styles.Heading}>A technology platform for live & on-demand streaming solutions. </h1>
      <p className={styles.Paragraph}>We have the in-house capability to provide end-to-end software and hardware solutions in the live streaming sector.</p>
    </section>
    <section className={styles.Section}>
      <h3 className={styles.Heading_3}>Innovative</h3>
      <p>The video streaming industry is constantly evolving. With our cutting edge technology, we enhance the virtual experience for our audience and keep on innovating to adapt and anticipate business requirements.</p>
      <h3 className={styles.Heading_3}>Intuitive</h3>
      <p>Our in-house team has expertise in technology operations and implementation. It’s comprised of individuals who are experienced, innovative and committed to delivering the best in class solutions for clients.</p>
      <h3 className={styles.Heading_3}>Powerful</h3>
      <p>All our data is secured and protected from unauthorized access via password protection, domain restrictions. Conference/Event link sharing and video download options are customizable based on client requirements.</p>
    </section>
    <section className={styles.Section}>
      <h3>Why us?</h3>
      <ol className={styles.MeritList}>
        {
          MERIT_LIST.map(item =>
            <li>
              <h3>{item.label}</h3>
              <p>{item.content}</p>
            </li>)
        }
      </ol>
    </section>

  </main>);
}
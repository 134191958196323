import { Link } from "react-router-dom";
import styles from './SubNav.module.css'




export default function SubNav(props: any) {
  const navItems: any[] = [];
  props.items.reduce(function (filtered: any[], item: any) {
    if (item.name !== 'All') {
      filtered?.push(
        <Link key={item.name} to={item.href} className={styles.Item}>
          <img src={item.iconUrl} alt="" className={styles.Icon} />
          <span className={styles.Name}>{item.name}</span>
        </Link>
      )
    }
    return filtered;
  }, navItems);

  return (
    <nav className={styles.SubNav}>
      {
        navItems
      }
    </nav>
  );
}
import ItemCard from "./item-card/item-card";

const ItemsList = (props: any) => {
  return (
    props.items.map((rental: any, index: number) => {
      return <ItemCard key={index} {...rental} />
    })
  );

}

export default ItemsList;


import { CategoryPath } from "../../../pages/rentals/models/catagory-path.type";
import { IRentalsNavigationCategory } from "./models/i-rentals-navigation-catagory";
import { rentalsNavigationList } from './rentals-navigation.list';

export class RentalsNavigationService {
  static _paths: IRentalsNavigationCategory[] = rentalsNavigationList;
  static getNavigations(): Promise<IRentalsNavigationCategory[]> {
    return new Promise((resolve, reject) => {
      resolve(this._paths)
    })
  }

  static extractCatagory(href: string) {
    return href.split('/').at(-1) ?? '';
  }

  static getCategoryPathObj(path: string) {
    return {
      params: {
        category: path
      }
    }
  }

  static getPagePaths(): Promise<CategoryPath[]> {
    let transformed = this._paths.map(
      category =>
        [
          this.getCategoryPathObj(this.extractCatagory(category.href)),
          ...Object.entries(category.navigations ?? {})
            .map(([key, value]) =>
              this.getCategoryPathObj(this.extractCatagory(value.href)))
        ]
    )
    return new Promise<CategoryPath[]>((res, ref) => res(transformed.flatMap(item => item)));
  }


  static getNavigationsForCatagory(href: string) {
    return this._paths.find(value => {
      return value.href === href
    })?.navigations || undefined;
  }

  static convertCategoryToSearch(category: string) {
    return decodeURIComponent(category).replaceAll(/\s|-/ig, '_').toUpperCase();
  }

  static canShowRentals() {
    let environment = process.env.NODE_ENV;
    let rentalsDomain = process.env.REACT_APP_WEBSITE_RENTALS_HOST;

    if (environment === 'production' && rentalsDomain === window.location.host) {
      return true;
    } else if (environment === 'development') {
      return true;
    } else {
      return false;
    }
  }
}



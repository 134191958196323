import { ENDORSEMENT_LIST } from './endorsements.list';
import styles from './page.module.css'
import Endorsement from './endorsment/endorsment'

export default function Testimonials() {
  return (
    <main className={styles.main}>
      <h1>Read what our customers are saying about us.</h1>
      {ENDORSEMENT_LIST.map((endorsmentItem, index) => <Endorsement endorsement={endorsmentItem} key={index + 1} />)}
    </main>
  );
} 
import { Outlet } from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "../../../root-layout/footer/footer";

export default function RentalsLayout() {
  return (
    <>
      {/* all the other elements */}
      <Header></Header>
      <Outlet />
      <Footer></Footer>
    </>
  );
}
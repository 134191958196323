import styles from './header.module.css';
import { NavLink } from "react-router-dom";
import { RentalsNavigationService } from '../../services/application/rentals/rentals-navigation.service';

interface LinkItem {
  href: string;
  name: string;
}

const navLinksList: LinkItem[] = [
  { href: "/rentals", name: 'Rentals' },
  { href: "/live-streaming", name: 'Streaming' },
  { href: "/testimonials", name: 'Testimonials' },
  { href: "/contact", name: 'Contact' },
];

const Header = () => {

  const navLinkActive = ({ isActive, isPending }: { isActive: boolean, isPending: boolean }): string => {
    return (isPending ? "pending" : isActive ? styles.active : "") + " " + styles.NavLink;
  }






  return (
    <header className={styles.header}>
      < NavLink
        to="/">
        <img className={styles.CompanyLogo} src="/images/whitebalance-logo.png" alt="Company Logo" width='350' />
      </NavLink >
      <nav className={styles.navLinksContainer}>
        {
          navLinksList
            .map((navLink: LinkItem, index) =>
              < NavLink
                key={index}
                to={navLink.href}
                className={navLinkActive}>
                {navLink.name}
              </NavLink >
            )
        }
      </nav>
    </header>
  );
}


export default Header;
import { WhereFilterOp, documentId, getFirestore } from 'firebase/firestore';
import firebaseApp from './FirebaseApp';
import { Firestore } from 'firebase/firestore/lite';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';

class FirestoreDB {
  static instance: FirestoreDB;

  private firestore!: Firestore;
  private avaliableCollection: Set<string> = new Set([]);

  private constructor() {
    this.firestore = getFirestore(firebaseApp);
    this.avaliableCollection = new Set(['Rentals', 'RentalCategories']);
  }

  getCollectionRef(collectionName: string) {
    return collection(this.firestore, collectionName);;
  }

  async isDocExists(collectionName: string, documentID: string) {
    const docRef = doc(this.firestore, collectionName, documentID);
    return getDoc(docRef).then(respone => respone.exists());
  }

  async getDocumentFromCollection(collectionName: string, documentID: string) {
    const docRef = doc(this.firestore, collectionName, documentID);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  async getCollectionData(collectionName: string, stub = true): Promise<any[]> {
    stub = false;
    if (!stub) {
      const collectionRef = this.getCollectionRef(collectionName);
      const documentQuery = query(collectionRef);
      const querySnapShot = await getDocs(documentQuery);
      return querySnapShot.docs.map(doc => doc.data());
    } else {
      return new Promise((resolve, reject) => {
        resolve(
          [
            {
              "imgURL": "https://firebasestorage.googleapis.com/v0/b/testing-69eb7.appspot.com/o/Accessories%2FLight%20Stands%2FBackground%20Stand.png?alt=media&token=f83f702d-a270-4ebb-89cf-2f1bc84d4145",
              "price": "500",
              "categories": {
                "LIGHT_STANDS": 1,
                "ACCESSORIES": 1
              },
              "name": "Background Stand",
              "id": "BACKGROUND_STAND",
              "tags": {
                "Accessories": 1,
                "Light Stand": 1
              }
            },
            {
              "categories": {
                "LIGHT_STANDS": 1,
                "ACCESSORIES": 1
              },
              "tags": {
                "Light Stand": 1
              },
              "imgURL": "https://firebasestorage.googleapis.com/v0/b/testing-69eb7.appspot.com/o/Accessories%2FLight%20Stands%2FC%20Stand%20with%20Boom.png?alt=media&token=c9ad7ca8-ecd4-4ffa-9eb1-47a02b53a831",
              "name": "C Stand with Boom",
              "id": "C_STAND_WITH_BOOM",
              "price": "100"
            },
            {
              "categories": {
                "LIGHT_STANDS": 1,
                "ACCESSORIES": 1
              },
              "name": "Double Height C Stand",
              "imgURL": "https://firebasestorage.googleapis.com/v0/b/testing-69eb7.appspot.com/o/Accessories%2FLight%20Stands%2FDouble%20Height%20C%20Stand.png?alt=media&token=d85277d0-44ae-4c79-964e-06612157a905",
              "id": "DOUBLE_HEIGHT_C_STAND",
              "tags": {
                "Light Stand": 1
              },
              "price": "200"
            },
            {
              "id": "LILLIPUT_7_HDMI_LCD_MONITOR",
              "name": "Lilliput 7 Inch HDMI LCD Monitor ",
              "tags": {
                "Accessories": 1,
                "Monitor": 1
              },
              "price": "500",
              "categories": {
                "MONITORS": 1,
                "ACCESSORIES": 1
              },
              "imgURL": "https://firebasestorage.googleapis.com/v0/b/testing-69eb7.appspot.com/o/Accessories%2FMonitor%2FLilliput%207%20Inch%20HDMI%20LCD%20Monitor.png?alt=media&token=ebbd476b-469e-42c3-b816-8f513393da91"
            },
            {
              "name": "Lilliput 7 Inch SDI LCD Monitor",
              "price": "600",
              "tags": {
                "Accessories": 1,
                "Monitors": 1
              },
              "imgURL": "https://firebasestorage.googleapis.com/v0/b/testing-69eb7.appspot.com/o/Accessories%2FMonitor%2FLilliput%207%20Inch%20SDI%20LCD%20Monitor.png?alt=media&token=b20b7d95-175c-4aac-aa02-872da0c24f0a",
              "id": "LILLIPUT_7_SDI_LCD_MONITOR",
              "categories": {
                "MONITORS": 1,
                "ACCESSORIES": 1
              }
            },
            {
              "categories": {
                "LIGHT_STANDS": 1,
                "ACCESSORIES": 1
              },
              "id": "SIMPEX_LIGHT_STAND_BIG_HEAVY_DUTY",
              "tags": {
                "Light Stand": 1
              },
              "name": "Simpex Light Stand Big Heavy Duty",
              "imgURL": "https://firebasestorage.googleapis.com/v0/b/testing-69eb7.appspot.com/o/Accessories%2FLight%20Stands%2FSimpex%20Light%20Stand%20Big%20Heavy%20Duty_.png?alt=media&token=11225393-df1d-4f0a-bff0-286d556e0ac6",
              "price": "200"
            },
            {
              "categories": {
                "LIGHT_STANDS": 1,
                "ACCESSORIES": 1
              },
              "price": "100",
              "id": "SIMPEX_MINI_LIGHT_STAND",
              "name": "Simpex Mini Light Stand",
              "imgURL": "https://firebasestorage.googleapis.com/v0/b/testing-69eb7.appspot.com/o/Accessories%2FLight%20Stands%2FSimpex%20Mini%20Light%20Stand_.png?alt=media&token=b6c0819e-9f40-4642-8b85-5709e7a2a03d",
              "tags": {
                "Light Stand": 1
              }
            },
            {
              "price": "1500",
              "tags": {
                "Accessories": 1,
                "Monitors": 1
              },
              "name": "Swit 17.3 Inch Director Monitor",
              "imgURL": "https://firebasestorage.googleapis.com/v0/b/testing-69eb7.appspot.com/o/Accessories%2FMonitor%2FSwit%2017.3.png?alt=media&token=42e5730e-c444-42dc-acb0-6b1f95f3ef86",
              "categories": {
                "MONITORS": 1,
                "ACCESSORIES": 1
              },
              "id": "SWIT_17.3_INCH_DIRECTOR_MONITOR"
            },
            {
              "tags": {
                "Light Stand": 1
              },
              "categories": {
                "LIGHT_STANDS": 1,
                "ACCESSORIES": 1
              },
              "imgURL": "https://firebasestorage.googleapis.com/v0/b/testing-69eb7.appspot.com/o/Accessories%2FLight%20Stands%2FHMI%20Par%20Triple%20Height%20Light%20Stand.png?alt=media&token=09cc9c19-51cf-422e-a977-ae144fdc9b7f",
              "name": "HMI Par Triple Height Light Stand",
              "price": "250",
              "id": "Simpex Light Stand Big Heavy Duty"
            },
            {
              "tags": {
                "Light Stand": 1
              },
              "imgURL": "https://firebasestorage.googleapis.com/v0/b/testing-69eb7.appspot.com/o/Accessories%2FLight%20Stands%2FTriple%20Riser%20C%20Stand.png?alt=media&token=1511b145-78d4-4c14-9ea4-2cc7cf84618c",
              "id": "TRIPLE_RISER_C_STAND",
              "categories": {
                "LIGHT_STANDS": 1,
                "ACCESSORIES": 1
              },
              "price": "200",
              "name": "Triple Riser C Stand"
            }
          ]
        )
      })
    }
  }

  async updateDocumentInCollection(collectionName: string, document: any) {
    const documentRef = doc(this.firestore, collectionName);
    const docSnapShot = await getDoc(documentRef);
    if (docSnapShot.exists()) {
      await setDoc(documentRef, document);
      return true;
    } else {
      return false;
    }
  }

  async runQueryOnCollection(collectionName: string, fieldPath: string, operation: WhereFilterOp, value: any) {
    const queryObj = query(this.getCollectionRef(collectionName), where(fieldPath, operation, value))
    const querySnapShot = await getDocs(queryObj);
    return querySnapShot.docs.map(doc => doc.data());
  }


  async queryDocumentIds(collectionName: string, idsList: string[]) {
    const queryObj = query(this.getCollectionRef(collectionName), where(documentId(), 'in', idsList));
    const querySnapShot = await getDocs(queryObj);
    return querySnapShot.docs.map(doc => doc.data());
  }

  static get Instance() {
    if (!this.instance) {
      FirestoreDB.instance = new FirestoreDB();
    }
    return this.instance;
  }
}

export {
  FirestoreDB
}

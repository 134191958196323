import { initializeApp, getApps, FirebaseApp } from "firebase/app";
import firebaseConfig from "./FirebaseConfig";

let firebaseApp: FirebaseApp;
// Initialize Firebase
if (getApps().length === 0) {
  firebaseApp = initializeApp(firebaseConfig)
} else {
  firebaseApp = getApps()[0]
}


export default firebaseApp;